<template>
  <div class="autumnOct">
    <img src="/subject/autumnOct/wap/banner.png" class="banner" alt="">
    <img src="/subject/autumnOct/wap/tit1.png" class="title" alt="">
    <div class="part1">
      <ul>
        <li>
          <img src="/subject/autumnOct/wap/ye1.png" class="leaf" alt="">
          <p>
            十月，是我国的收货之季，一片片稻田闪烁着金色的光芒、饱满的硕果正摇摇欲坠，秋菊绽放、桂花飘香，金黄色的落叶在空中飘舞，人们脸上洋溢着幸福的笑容；
          </p>
        </li>
        <li>
          <img src="/subject/autumnOct/wap/ye2.png" class="leaf" alt="">
          <p>
            十月，也是伦敦金需求旺盛之月，十月的日子总寓意着十全十美，大吉大利，是婚嫁和新店开张的好时节，此时伦敦金制品总是用来作为美好祝福的象征；
          </p>
        </li>
        <li>
          <img src="/subject/autumnOct/wap/ye3.png" class="leaf" alt="">
          <p>
            十月，还是伦敦金/银产品的投资旺季，人们会在这个丰收之月开始新一年的投资计划，因现货伦敦金具有起点低、双向交易、易上手等特点，所以“炒金”成为越来越多数人的选择；
          </p>
        </li>
      </ul>
    </div>
     <img src="/subject/autumnOct/wap/tit2.png" class="title" alt="">
     <div class="part2">
      <a href="https://www.rlcproltd.net/uc/signUp/real"></a>
     </div>
     <img src="/subject/autumnOct/wap/tit3.png" class="title" alt="">
     <div class="part3">
      <p>
        十月迎新，开户得<span>$300</span>现金券<br/>
        先交易再入金，给自己一个体验的机会<br/>
        首入金更有最高<span>$7500+￥500</span>投资礼包
      </p>
       <a href="https://www.rlcproltd.net/uc/signUp/real"></a>
     </div>
      <img src="/subject/autumnOct/wap/tit4.png" class="title" alt="">
      <div class="part4">
        <div class="box">
          <a href="https://www.rlcproltd.net/uc/signUp/real"></a>
        </div>
      </div>
       <img src="/subject/autumnOct/wap/tit5.png" class="title" alt="">
      <div class="part5">
       <p>
        专业权威机构监管<br>
        香港金银业贸易场第081号行员
       </p>
       <img src="/subject/autumnOct/wap/pic2.png" class="pic2" alt="">
       <img src="/subject/autumnOct/wap/pic3.png" class="pic3" alt="">
       <div class="box">
          <h1>
          诚信至上，客户为本<br>
          专业、安全、国际化标准
        </h1>
        <ul>
          <li><i></i>银行级别安全体系，出金最快2小时到账；</li>
          <li><i></i>国际标准编码技术，交易直达国际市场；</li>
          <li><i></i>高端加密交易信息，严格保密用户个人信息；</li>
          <li><i></i>风控团队实时护航，保障用户无忧交易；</li>
        </ul>
       </div>
      </div>
  </div>
</template>

<script>
export default {
  name:'autumnOctWap',
  created(){
    if(document.body.clientWidth>500){
      this.$router.push({
        name:'autumnOctPC',
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .autumnOct{
    width: 100%;
    background: #fdf5e6;
    .banner{
      width: 100%;
      height: 265.5px;
      display: block;
    }
    .title{
      width: 100%;
      height: 45px;
      display: block;
    }
    .part1{
      
      padding-top: 20px;
      padding-bottom: 20px;
      ul{
        li{
          width: 91%;
          display: block;
          margin: 0 auto;
          height: 220px;
          box-sizing: border-box;
          padding-top: 137px;
          position: relative;
          margin-bottom: 20px;
          p{
            color: #a86e4f;
            margin: 0 auto;
            width: 80%;
            text-align: center;
            font-size: 13px;
          }
        }
        
        li:nth-child(1){
          background: url('/subject/autumnOct/wap/li1.png') no-repeat center;
          background-size: cover;
          .leaf{
            position: absolute;
            right: -19px;
            top: -18px;
            width: 22px;
          }
        }
        li:nth-child(2){
          background: url('/subject/autumnOct/wap/li2.png') no-repeat center;
          background-size: cover;
          .leaf{
            position: absolute;
            left: -15px;
            bottom: -12px;
            width: 30px;
          }
        }
        li:nth-child(3){
          background: url('/subject/autumnOct/wap/li3.png') no-repeat center;
          background-size: cover;
          .leaf{
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 22px;
          }
        }
      }
    }
    .part2{
      width: 344px;
      height: 308px;
      display: block;
      margin: 0 auto;
       background: url('/subject/autumnOct/wap/part2.png') no-repeat center;
      background-size: cover;
      margin-top: 10px; 
      box-sizing: border-box;
      padding-top: 256px;
      a{
        display: block;
        margin: 0 auto;
        background: url('/subject/autumnOct/wap/btn1.png') no-repeat center;
        background-size: cover;
        width: 150px;
        height: 40px;
      }
    }
    .part3{
           width: 350px;
        height: 350px;
        display: block;
        margin: 0 auto;
        background: url('/subject/autumnOct/wap/part3.png') no-repeat center;
          background-size: cover;
          box-sizing: border-box;
          padding-top: 186px;
          p{
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            color: #a86e4f;
            span{
              color: #e34a3f;
            }
          }
          a{
            display: block;
            margin: 0 auto;
            background: url('/subject/autumnOct/wap/btn2.png') no-repeat center;
            background-size: cover;
            width: 185px;
            height: 40px;
            margin-top: 10px;
          }
    }
    .part4{
     width: 380px;
      height: 258px;
      display: block;
      margin: 0 auto;
       background: url('/subject/autumnOct/wap/piaodai.png') no-repeat center;
      background-size: 388px 167px;
      box-sizing: border-box;
      padding-top: 10px;
      .box{
        width: 304px;
        height: 230px;
        margin: 0 auto;
        background: url('/subject/autumnOct/wap/redbag.png') no-repeat center;
        background-size: cover;
        box-sizing: border-box;
        padding-top: 130px;
         a{
            display: block;
            margin: 0 auto;
            background: url('/subject/autumnOct/wap/btn3.png') no-repeat center;
            background-size: cover;
            width: 285px;
            height: 40px;
            margin-top: 10px;
          }
      }
    }
    .part5{
      padding-top: 10px;
      position: relative;
      p{
        text-align: center;
        font-size: 13px;
        color: #a86e4f;
        line-height: 21px;
        margin-bottom: 20px;
      }
      img{
        display: block;
        margin: 0 auto;
      }
      .pic2{
        width: 300px;
        margin-bottom: 50px;
      }
      .pic3{
        width: 280px;
      }
      .box{
        background: url('/subject/autumnOct/wap/bgbottom.png') no-repeat center;
        background-size: 100% 100%;
        padding-bottom: 30px;
      }
      h1{
        text-align: center;
        font-size:18px;
        color: #a86e4f;
        line-height: 24px;
      }
      ul{
        list-style-type:disc;
        li{
          text-align: left;
          font-size: 13px;
          color: #a86e4f;
          padding-left: 67px;
          i{
            display: inline-block;
            width: 7px;
            height: 7px;
            background: #a86e4f;
            border-radius: 50px;
            margin-right: 5px;
          position: relative;
          top: -1px;
          }
        }
      }
    }
  }
</style>